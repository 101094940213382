@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "DB_Heavent";
    src: url("./assets/fonts/DB_Heavent/DBHeaventOriginal/DB_Heavent_v3.2.1.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "DB_Heavent";
    src: url("./assets/fonts/DB_Heavent/DBHeaventOriginal/DB_Heavent_Med_v3.2.1.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "DB_Heavent";
    src: url("./assets/fonts/DB_Heavent/DBHeaventOriginal/DB_Heavent_Med_v3.2.1.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "DB_Heavent";
    src: url("./assets/fonts/DB_Heavent/DBHeaventOriginal/DB_Heavent_Bd_v3.2.1.ttf");
    font-weight: 700;
    font-style: normal;
}



@font-face {
    font-family: "DB_Heavent";
    src: url("./assets/fonts/DB_Heavent/DBHeaventOriginal/DB_Heavent_It_v3.2.1.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "DB_Heavent";
    src: url("./assets/fonts/DB_Heavent/DBHeaventOriginal/DB Heavent Li v3.2.1.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "DB_Heavent";
    src: url("./assets/fonts/DB_Heavent/DBHeaventOriginal/DB Heavent Li It v3.2.1.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}


/* body {
    font-family: "DB_Heavent", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
} */

:root {
  --primary-color: #EC2028;
  --primary-dark-color: #a5161c;
  --primary-light-color: #ef4c53;
  --primary-font-color: #292929;
  --secondary-font-color: #868686;
  --primary-bg-color: #ffffff;
  --secondary-bg-color: #F3F3F3;
  --dark-bg-color: #434343;
  --color-border: #989898;

  color: var(--primary-font-color);
  /* font-family: "DB_Heavent", sans-serif;
  font-size: 24px;
  line-height: 1.0; */
}



.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--primary-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
}